import React from 'react'
import Layout from "../components/layout";
import { Helmet } from 'react-helmet';

function Download() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Download - Bart Sallé, freelance webdeveloper";
  const description = "Uit mijn liefde voor Open Source wil ik soms wat terug doen. Hier vind je mijn plugins voor Wordpress en Bludit. Download ze gratis!";
  const url = "https://www.bartsalle.nl/download/";


  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="download" />
      </Helmet>
        <div className="container">
          <h1>Download</h1>
          <p>In de weinige vrije uurtjes die ik heb vind ik het leuk om iets terug te doen voor de Open Source wereld. Zo schrijf ik plugins en templates voor bekende en minder bekende Open Source content management systemen zoals WordPress en Bludit.</p>
          <h2>Mijn WordPress plugins</h2>
          <p className="iframeholder"><iframe class="wp-embedded-content" sandbox="allow-scripts" security="restricted" title="“COVID-19 Float Button” — Plugin Directory" src="https://wordpress.org/plugins/covid-19-float-button/embed/#?secret=3Nt1UAfXJb" data-secret="3Nt1UAfXJb" width="600" height="220" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></p>
          <p><a href="https://wordpress.org/plugins/covid-19-float-button/" target="_blank">https://wordpress.org/plugins/covid-19-float-button/</a></p>
          <h2>Mijn Bludit plugins</h2>
          <p>Bludit is een zogeheten flat-file CMS en blinkt uit in zijn eenvoud. Een goed alternatief voor een zwaarder CMS als Wordpress.</p>
          <p>Bludit Extended Maintenance Mode plugin</p>
          <p>Download:</p>
          <p><a href="https://plugins.bludit.com/plugin/maintenance-mode-extended" target="_blank">https://plugins.bludit.com/plugin/maintenance-mode-extended</a></p>
          <p>Github:</p>
          <p><a href="https://github.com/barthusz/bludit_maintenance-mode-extended" target="_blank">https://github.com/barthusz/bludit_maintenance-mode-extended</a></p>
        </div>
    </Layout>
  )
}

export default Download